<template>
  <div>
    <div class="register-property-layout">
      
      <!-- FROMS -->
      <div class="forms-container">
      
        <div class="container">
          <ul id="progressbar">
            <!-- <li class="active" :class="[isForm1Valid && formStep == 1 ? 'active' : '']"> -->
            <li class="" :class="[isForm1Valid  ? 'active' : '']">
              {{ isMobile ? 'Info.' : 'Basic Info.' }}
            </li>
            <li :class="[isForm2Valid  ? 'active' : '']">
              {{ isMobile ? 'Feature' : ' Property feature' }}
            </li>
            <li :class="[isForm3Valid  ? 'active' : '']">
              {{ isMobile ? 'Media' : 'Images & media' }}
            </li>
            <li :class="[isForm3Valid && formStep == 4 ? 'active' : '']">
              {{ isMobile ? 'Finish' : 'Finalize & Publish' }}
            </li>
          </ul>
          <!-- FORM 1 -->
        <div class="" v-if="formStep == 1">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <!-- <div class="container__head">
            <h3>Please provide the Landlord Information</h3>
            <p>
              Please fill out the following details for accurate registration
            </p>
          </div> -->

            <div class="form">
              <div>
                  <div class="form-check">
                    <p class="radio-label">What is the property availability?</p>
                    <input class="form-check-input" type="radio" name="propAvailability" id="rent" value="1" v-model="availability">
                    <label class="" for="rent">
                      For Rent
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="propAvailability" id="sale" value="3" v-model="availability">
                    <label class="form-check-label" for="sale">
                      For Sale
                    </label>
                  </div>
              </div>
              <div class="input-group">
                <div class="email-container input-full">
                <BaseInput
                  type="text"
                  placeholder="Eg: newly built 3 bedroom flat"
                  label="Title"
                  :value="propertyTitle"
                  @input="propertyTitle = $event"
                  :errorMessage="errorMessage.title"
                  @blur="validateTitleInput"
                />
              </div>
              <BaseInput
                  placeholder="Enter amount"
                  label="Amount"
                  :value="theRentValueAmount"
                  @input="theRentValueAmount = $event"
                  :errorMessage="errorMessage.amount2"
                  @blur="validatePropertyValue"
                />
              </div>

              <div class="input-group">
                <InputSelect
                label="Property Type"
                @select-option="propertyType = $event"
                @blur="validatePropertyType"
                :value="propertyType"
                :errorMessage="errorMessage.propertyType"
                :loading="loadingPropertyType"
              >
                <option
                  v-for="propertyType in propertyTypes.slice(0, 2)"
                  :key="propertyType.id"
                  :value="propertyType.type"
                  >{{ propertyType.type }}</option
                >
                </InputSelect>
                  <InputSelect
                    label="Property subtype"
                    @select-option="propertySubType = $event"
                    @blur="validatePropertySubType"
                    :value="propertySubType"
                    :errorMessage="errorMessage.propertySubType"
                    :loading="loadingPropertySubType"
                  >
                    <option
                      v-for="subType in propertySubTypes"
                      :key="subType.id"
                      :value="subType.subtype"
                      >{{ subType.subtype }}</option
                    >
                  </InputSelect>
                <!-- <InputSelect
                  label="Business Type"
                  @select-option="typeofbusiness = $event"
                  @blur="validateBusinessType"
                  :value="typeofbusiness"
                  :errorMessage="errorMessage.typeofbusiness"
                >
                  <option selected disabled>Select</option>
                  <option  v-for="item in businesstypenumber"
                    :key="item.id"
                    :value="item.id">{{ item.businesstype }}</option>
                </InputSelect> -->
              </div>

                <div class="input-group">
                  <InputSelect
                    label="State"
                    @select-option="location = $event"
                    @blur="validateLocation"
                    :value="location"
                    :errorMessage="errorMessage.location"
                    :loading="loadingLocation"
                  >
                    <option
                      v-for="state in sortedStates"
                      :key="state.id"
                      :value="state.name"
                      >{{ state.name }}</option
                    >
                  </InputSelect>

                  <InputSelect
                    label="City"
                    @select-option="city = $event"
                    @blur="validateCity"
                    :value="city"
                    :errorMessage="errorMessage.city"
                    :loading="loadingCity"
                  >
                    <option
                      v-for="city in sortedCity"
                      :key="city.id"
                      :value="city.name"
                      >{{ city.name }}</option
                    >
                  </InputSelect>
                  
                </div>
              <!-- <div class="input-group">
              <InputSelect
                label="Status"
                @select-option="propertyStatus = $event"
                @blur="validateStatus"
                :value="propertyStatus"
                :errorMessage="errorMessage.status"
                :loading="loadingStatus"
              >
                <option
                  v-for="status in propertyStatuses"
                  :key="status"
                  :value="status"
                  >{{ status }}</option
                >
              </InputSelect>
            </div> -->
                <!-- <div class="btn-container">
                <BaseButtton type="text" @click="formStep--"
                  >Previous</BaseButtton
                >
                <BaseButtton @click="validateForm2">Next</BaseButtton>
              </div> -->
            </div>
          
          <div class="btn-container  flex-end" style="padding: 1rem;">
            <BaseButtton @click="validateForm1">Next</BaseButtton>
          </div>
        </div>
        <!-- FORM 2 -->
        <div class="" v-if="formStep == 2">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="form">
            
            <div class="email-container input-full">
              <div class="input-group">
                <BaseInput
                  type="number"
                  placeholder="Enter number of bedroom(s)"
                  label="How many bedrooms"
                  :value="noOfBedroom"
                  @input="noOfBedroom = $event"
                  :errorMessage="errorMessage.noOfBedroom"
                  @blur="validateBedrooms"
                />

                <BaseInput
                  type="number"
                  placeholder="Enter number of bathroom(s)"
                  label="How many bathrooms"
                  :value="noOfBathroom"
                  @input="noOfBathroom = $event"
                  :errorMessage="errorMessage.noOfBathroom"
                  @blur="validateBathrooms"
                />
              </div>
            </div>
            <div class="email-container input-full">
              <div class="input-group">
                <BaseInput
                  type="number"
                  placeholder="Enter number of toilet(s)"
                  label="How many toilets"
                  :value="noOfToilets"
                  @input="noOfToilets = $event"
                  :errorMessage="errorMessage.noOfToilets"
                  @blur="validateToilets"
                />

                <BaseInput
                  type="text"
                  placeholder="Enter total square footage"
                  label="Total square footage"
                  :value="squareFootage"
                  @input="squareFootage = $event"
                />
              </div>
            </div>
            <BaseInput
              type="textarea"
              placeholder="enter a detailed details of the property"
              label="Property description"
              :value="description"
              @input="description = $event"
              :errorMessage="errorMessage.description"
              @blur="validateDescription"
            />

            <div class="features-amenities-holder">
              <p class="radio-label">Select the key features and amenities </p>
              <div class="" >
                <button @click="selectFeatures(item)" class="features-btn btn" :class="{ 'selectedFeature': isSelected(item) }" v-for="(item, index) in facilitiesAmenities"
                :key="index">{{ item }}</button>
              </div>
            </div>
            <div class="btn-container"  style="padding: 1rem;">
              <BaseButtton type="text" @click="formStep--"
                >Previous</BaseButtton
              >
              <BaseButtton @click="validateForm2">Next</BaseButtton>
            </div>
          </div>

        </div>

        <!-- FORM 3 -->
        <div class="" v-if="formStep == 3">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>

          <div class="form">
            <div class="media-holder">
              <h4>Upload your Mandate Letter</h4>
              <p class="radio-label">Please upload of proof of ownership  document</p>
              <div class="upload-area" @click="selectFileEvidenceOfProperty">
                <img src="../assets/icons/upload.svg" alt="upload">
                <p v-if="!uploadingEvidence">Click to upload</p>
                <p class="uploading-doc" v-if="uploadingEvidence">Uploading document....</p>
                <small>PDF</small>
                <br />
                <small>Document should not be more than 5MB</small>          
                <input @blur="validatePropertyDoc" type="file" ref="propertyEvidence" @change="handleFileUploadEvidence($event)" class="d-none" >
                
                <div class="mt-4 d-flex justify-content-center" v-if="evidenceServerFileName">
                  <div class="file-display">
                    <h6>{{evidenceServerFileName}}</h6>
                  </div>
                </div>
                <h6 class="error-invisible">{{ errorMessage.evidenceFile }}</h6>
              </div>
            </div>

            <div class="media-holder mt-3">
              <h4>Add some photos of your property</h4>
              <p class="radio-label">You'll need 5 photos to get started. You can add more or make changes later. </p>
              <div class="upload-area" @click="selectPropertyImage">
                <img src="../assets/icons/upload.svg" alt="upload">
                <p v-if="!uploadingPhoto">Add photos</p>
                <small>Photo</small>
                <br />
                <small>Each image should not be more than 5MB</small>
                <p class="uploading-doc" v-if="uploadingPhoto">Uploading photo....</p>
                <!-- <div class="file-upload-selected">
                  <p>
                    property-document.png
                  </p>
                </div> -->
                <input @blur="validatePropertyImage" type="file" ref="propertyImage" @change="handleFileUploadImages($event)" class="d-none" >
                <div class="">
                  <div class="row justify-content-center">
                    <div class="" v-for="(item, index) in uploadedPhotos"
                      :key="index">
                      <div class="file-display">
                        <h6>{{item}}<span class="" @click.stop="removeUploadedImages(index)">X</span></h6>
                      </div>
                    </div>
                    <!-- <div class="">
                      <div class="file-display">
                        <h6>photo.jpeg <span>X</span></h6>
                      </div>
                    </div>

                    <div class="">
                      <div class="file-display">
                        <h6>photo.jpe <span>X</span></h6>
                      </div>
                    </div>

                    <div class="">
                      <div class="file-display">
                        <h6>photo.jpeg <span>X</span></h6>
                      </div>
                    </div>
                     -->
                  </div>
                </div>
                <h6 class="error-invisible">{{ errorMessage.propertyImageFile }}</h6>
                <!-- <div class="submit-photo-btn">
                  <button class="btn">Submit photos</button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="btn-container"  style="padding: 1rem;">
            <BaseButtton type="text" @click="formStep--">Previous</BaseButtton>
            <BaseButtton @click="validateForm3">
              Next
            </BaseButtton>
            <!-- <BaseButtton @click="submitForm" :disabled="processing">
              {{ processing ? "Processing..." : "Submit" }}
            </BaseButtton> -->
          </div>
        </div>

        <!-- FORM 4 validate -->
        <div class="" v-if="formStep == 4">
          <div class="form finish-holder">
            <div class="finish-header">
              <h5>Preview Your Property Listing</h5>
              <p>Here's how your property will appear to potential buyers or renters. Ensure all details are accurate and appealing.</p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="property-preview-holder" @click="propertyDetailsView()">
                  <div>
                    <img v-if="propertyFirstImage" class="preview-img" :src="propertyFirstImage" alt="image">
                    <img v-else class="preview-img" src="../assets/images/property-img.jpg" alt="image">
                  </div>
                  <div class="prop-preview-details">
                    <h5>{{propertyTitle}}</h5>
                    <h6>{{city}}, {{location}}</h6>
                    <h4>₦{{amount}}</h4>
                    <small>{{ noOfBedroom }} bedroom .{{ noOfBathroom }} bathroom</small>
                    <p>Listed by {{ user.lastname }} | <span>{{ availability == 1 ? 'For sale' : 'For rent' }}</span> </p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-4">
                <div class="property-preview-holder">
                  <div>
                    <img class="preview-img" src="../assets//images/property-img.jpg" alt="image">
                  </div>
                  <div class="prop-preview-details">
                    <h5>Fully Furnished Smart Studio...</h5>
                    <h6>Garriki, Abuja</h6>
                    <h4>₦1,000,000</h4>
                    <small>1 bedroom .1 bathroom</small>
                    <p>Listed by Joe Doe | <span>For sale</span> </p>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-4">
                <div class="property-preview-holder">
                  <div>
                    <img class="preview-img" src="../assets//images/property-img.jpg" alt="image">
                  </div>
                  <div class="prop-preview-details">
                    <h5>Fully Furnished Smart Studio...</h5>
                    <h6>Garriki, Abuja</h6>
                    <h4>₦1,000,000</h4>
                    <small>1 bedroom .1 bathroom</small>
                    <p>Listed by Joe Doe | <span>For sale</span> </p>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="btn-container"  style="padding: 1rem;">
              <BaseButtton type="text" @click="formStep--"
                >Previous</BaseButtton
              >
              <BaseButtton @click="submitForm()">
                <span v-if="!propertyLoader">Finish</span>
                <span class="submitting-text" v-if="propertyLoader">Submitting....</span>
              </BaseButtton>
            </div>
          </div>
        </div>
        <!-- STEP FOUR ENDS -->
        </div>
      </div>
      <aside class="steps-container d-none">
        <h4>Property registeration</h4>

        <div class="steps">
          <div
            class="step"
            :class="{
              progress: formStep == option.id,
              completed: formStep > option.id,
            }"
            v-for="option in formStepDetails"
            :key="option.id"
          >
            <div class="step-number-container">
              <div class="step-number">
                {{ option.id }}
                <div class="done-icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="check">
                      <path
                        id="Vector"
                        d="M13.3337 4L6.00033 11.3333L2.66699 8"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <p class="step-count">Step {{ option.id }}</p>
              <p class="current-step-name">{{ option.text }}</p>
              <div class="status-pill">
                {{
                  formStep == option.id
                    ? "In progress"
                    : formStep > option.id
                    ? "completed"
                    : "pending"
                }}
              </div>
            </div>
          </div>
          <!-- <div class="progress step">
            <div class="step-number-container">
              <div class="step-number">1</div>
            </div>
            <div>
              <p class="step-count">Step 1</p>
              <p class="current-step-name">Landlord Information</p>
              <div class="status-pill progress">completed</div>
            </div>
          </div>
          <div class="step"> 
            <div class="step-number-container">
              <div class="step-number">1</div>
            </div>
            <div>
              <p class="step-count">Step 1</p>
              <p class="current-step-name">Landlord Information</p>
              <div class="status-pill progress">completed</div>
            </div>
          </div>
          -->
        </div>
      </aside>
    </div>

    <!-- STEPS -->
  </div>
</template>

<script>
import properties from "../services/properties";
import InputSelect from "../components/form/InputSelect.vue";
import BaseButtton from "../components/UI/BaseButtton.vue";

import BaseInput from "../components/form/BaseInput.vue";
// import { registerLandlordAndProperty } from "../services/promo";
export default {
  components: {
    BaseInput,
    InputSelect,
    BaseButtton,
  },
  data() {
    return {
      uploadingEvidence: false,
      uploadingPhoto: false,
      user: "",
      formStepDetails: [
        {
          id: 1,
          text: "Landlord Information",
        },
        {
          id: 2,
          text: "Property details",
        },
        {
          id: 3,
          text: "Finish",
        },
      ],
      processing: false,
      isMobile: false,
      propertyLoader: false,
      loadingPropertyType: false,
      loadingPropertySubType: false,
      loadingAwareness: false,
      loadingLocation: false,
      loadingCity: false,
      loadingStatus: false,
      formStep: 1,
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      gender: "",
      address: "",
      amount: "",
      amount2: "",
      age: "",
      availability: 1,
      propertyTitle: "",
      propertyTypes: [],
      propertySubTypes: [],
      propertySubType: "",
      propertyType: "",
      propertyValue: "",
      noOfBedroom: "",
      noOfBathroom: "",
      noOfToilets: "",
      description: "",
      // leaseOrRent: "",
      isFurnished: "no",
      propertyStatuses: [],
      propertyStatus: "",
      businesstypenumber: "",
      propertyAware: "",
      squareFootage: "",
      states: [],
      isEmailValid: false,
      location: "",
      city: "",
      cities: [],
      stateIdd: "",
      propertyAwarenessTypes: [],
      bedroomOptions: [],
      denomination: "naira",
      // businesstype: 1,
      typeofbusiness: "",
      subscription: "",
      subtypeId: "",
      facilities: "",
      evidenceServerFileName: "",
      evidenceFile: "",
      errorMessage: {
        title: "",
        noOfBedroom: "",
        noOfBathroom: "",
        noOfToilets: "",
        propertyType: "",
        propertySubType: "",
        squareFootage: "",
        description: "",
        // leaseOrRent: "",
        // typeofbusiness: "",
        status: "",
        propertyValue: "",
        form: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        amount: "",
        amount2: "",
        address: "",
        age: "",
        gender: "",
        location: "",
        city: "",
        // evidenceServerFileName: "",
        propertyImageFile: "",
        evidenceFile: "",
      },
      selectedFeaturesNames: [],
      facilitiesAmenities: [],
      uploadedPhotos: [],
      propertyImageFile: "",
      propertyFirstImage: ""
    };
  },

  methods: {
    selectFeatures(name) {
      const index = this.selectedFeaturesNames.indexOf(name);
      if (index === -1) {
        this.selectedFeaturesNames.push(name);
      } else {
        this.selectedFeaturesNames.splice(index, 1);
      }

    },
    isSelected(name) {
      return this.selectedFeaturesNames.includes(name);
    },
    selectFileEvidenceOfProperty() {
      this.$refs.propertyEvidence.click();
    },
    selectPropertyImage() {
      this.$refs.propertyImage.click();
    },
    handleFileUploadEvidence(event) {
      this.evidenceFile = event.target.files[0];
      // const file = event.target.files[0];
      this.uploadingEvidence = true;
      this.evidenceFileName = this.evidenceFile.name;

      if (!this.evidenceFile) {
        this.uploadingEvidence = false;
        // this.$toast.success("success");
        return this.$toast.error("select a file");
      }
      if (
          this.evidenceFile.type === "application/pdf"
          // this.evidenceFile.type === "image/jpeg" ||
          // this.evidenceFile.type === "image/jpg" ||
          // this.evidenceFile.type === "image/png" ||
          // this.evidenceFile.type === "image/gif" ||
          // this.evidenceFile.type === "application/pdf" ||
          // this.evidenceFile.type === "svg+xml"
      ) {
        this.uploadingEvidence = true;
      } else {
        this.uploadingEvidence = false;
        this.evidenceFile = "";
        return this.$toast.error("select a PDF file");
      }
      
      // Handle the uploaded file here
      const sizeInMb = this.evidenceFile.size / (1024 * 1024).toFixed(3);
      if (sizeInMb > 5) {
        this.uploadingEvidence = false;
        return this.$toast.error("File size more than 5MB");
      }
      let formData = new FormData();
      formData.append("file", this.evidenceFile);
      formData.append("documentType", this.evidenceFile.name);
      properties.uploadPropertyEvidenceOfOwener(formData)
      .then((res) => {
        this.uploadingEvidence = false;
        // console.log("evidence File", res);
        if (res.success) {
            this.evidenceServerFileName = res.filename
          this.$toast.success("SUCCESS");
        } else {
          this.$toast.error("ERROR");
        }
      })
      .catch((err) => {
        console.log(err);
        this.uploadingEvidence = false;
      });

    },
    handleFileUploadImages(event) {
      this.propertyImageFile = event.target.files[0];
      const file = event.target.files[0];
      // Handle the uploaded file here
      this.propertyImageFile = event.target.files[0];
      // const file = event.target.files[0];
      this.uploadingPhoto = true;
      if (this.uploadedPhotos.length == 0) {
        this.propertyFirstImage = URL.createObjectURL(file);
      }
     

      if (!this.propertyImageFile) {
        this.uploadingPhoto = false;
        // this.$toast.success("success");
        return this.$toast.error("select a file");
      }
      if (
          // this.evidenceFile.type != "application/pdf"
          this.propertyImageFile.type === "image/jpeg" ||
          this.propertyImageFile.type === "image/jpg" ||
          this.propertyImageFile.type === "image/png" ||
          this.propertyImageFile.type === "image/gif" ||
          // this.propertyImageFile.type === "application/pdf" ||
          this.propertyImageFile.type === "svg+xml"
      ) {
        this.uploadingPhoto = true;
      } else {
        this.uploadingPhoto = false;
        this.propertyImageFile = "";
        return this.$toast.error("select a IMAGE file");
      }
      
      // Handle the uploaded file here
      const sizeInMb = this.propertyImageFile.size / (1024 * 1024).toFixed(3);
      if (sizeInMb > 5) {
        this.uploadingPhoto = false;
        return this.$toast.error("File size more than 5MB");
      }
      let formData = new FormData();
      formData.append("file", this.propertyImageFile);
      formData.append("documentType", this.propertyImageFile.name);
      properties.uploadPropertyimages(formData)
      .then((res) => {
        this.uploadingPhoto = false;
        if (res.success) {
          JSON.stringify(this.uploadedPhotos.push(res.filename));
          window.localStorage.setItem("__uploadedImages", JSON.stringify(this.uploadedPhotos))
          // console.log("Uploaded file", this.uploadedPhotos);
          this.$toast.success("SUCCESS");
        } else {
          this.$toast.error("ERROR");
        }
      })
      .catch((err) => {
        console.log(err);
        this.uploadingPhoto = false;
      });

    },

    removeUploadedImages(index) {
      this.uploadedPhotos.splice(index, 1);
    },

    submitForm() {
      this.propertyLoader = true;
      this.errorMessage.form = "";
      if (this.subscription != "YES") {
        return this.$toast.error("Subscribe before adding property");
      }
      if (this.subscription == "YES") {
        const body = {
          title: this.propertyTitle,
          // lastname: this.lastName,
          // firstname: this.firstName,
          // gender: this.gender,
          type: this.propertyTypeId,
          subtype: this.propertySubTypeId,
          bedrooms: this.noOfBedroom,
          bathrooms: this.noOfBathroom,
          toilets: this.noOfToilets,
          squarefootage: this.squareFootage,
          stateid: this.stateId,
          cityid: this.cityId,
          price: this.amount2,
          businesstype: Number(this.availability),
          evidenceofownership: this.evidenceServerFileName,
          facilities: this.selectedFeaturesNames,
          image_array: this.uploadedPhotos,
          // denomination: "naira",
          // businesstype: Number(this.typeofbusiness),
          // email: this.email,
          // phone: this.phone,
          status: this.propertyStatus,
          description: this.description,
        };
        properties.agentRegisterProperty(JSON.stringify(body))
          .then((res) => {
            this.propertyLoader = false;
            if (res.success) {
              (this.errorMessage.form = ""), (this.formSubmitted = true);
              this.clearForm();

              this.$toast.success("FORM SUBMITTED SUCCESSFULLY");
              // reset form step
              this.formStep = 1;
            } else {
              this.$toast.error("ERROR");
            }
          })
          .catch((err) => {
            console.log(err);
            this.propertyLoader = false;
          });
      } else {
        this.propertyLoader = false
        this.errorMessage.form = "Unable to submit. Please try again";
      }
    },

    clearForm() {
      this.typeofbusiness = "";
      this.propertyTitle = "";
      this.propertyType = "";
      this.propertySubType = "";
      this.noOfBedroom = "";
      this.noOfBathroom = "";
      this.noOfToilets = "";
      this.state = "";
      this.propertyValue = "";
      this.email = "";
      this.evidenceServerFileName = "";
      this.propertyImageFile = "";
      this.uploadingEvidence = "";
      this.uploadedPhotos = [];
      this.landlordPhone = "";
      this.propertyStatus = "";
      this.description = "";
      // this.leaseOrRent = "";
      this.typeofbusiness = ""
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = "";
      this.amount2 = "";
      this.amount = "";
      this.address = "";
      this.age = "";
      this.gender = "";
      this.location = "";
      this.city = "";
      this.facilities = "";
      this.evidenceFile = "";
    },

    facilitiesAndAmenities() {
      properties.fetchFacilitiess().then((res) => {
        // console.log("facilities", res);
        this.facilitiesAmenities =  res.facilities;
      }).catch((err) => {
        console.log(err);
      });
    }, 

    getPropertyTypes() {
      this.loadingPropertyType = true;
      //fetch properties
      properties
        .fetchPropertyType()
        .then((data) => {
          //fetch subproperty on first load

          // this.getPropertySubTypes();
          this.propertyTypes = data.propertytypes;
          this.loadingPropertyType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBusinessTypes() {
      //fetch properties
      properties
        .fetchBusinessTypes()
        .then((data) => {
          // console.log("Business Data", data)
          if (data.success === "success") {
            this.businesstypenumber = data.businesstype;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertySubTypes(id) {
      this.loadingPropertySubType = true;
      properties
        .fetchPropertySubType(id)
        .then((data) => {
          this.propertySubTypes = data.propertysubtypes;
          this.loadingPropertySubType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStates() {
      this.loadingLocation = true;
      properties
        .fetchStates()
        .then((data) => {
          this.loadingLocation = false;
          // this.getCities();
          this.states = data.states;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCities(id) {
      this.loadingCity = true;
      properties
        .fetchCities(id)
        .then((data) => {
          this.loadingCity = false;

          this.cities = data.cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPropertyAwarenessTypes() {
      this.loadingAwareness = true;

      properties.fetchPropertyAwarenessTypes().then((res) => {
        this.propertyAwarenessTypes = res.data;
        this.loadingAwareness = false;
      });
    },
    getPropertyStatuses() {
      this.loadingStatus = true;
      properties.fetchPropertyStatuses().then((data) => {
        this.propertyStatuses = data.propertystatus;
        this.loadingStatus = false;
      });
    },

    validateTitleInput() {
      if (this.propertyTitle.trim() === "") {
        this.errorMessage.title = " Please input title";
        return false;
      }
      this.errorMessage.title = "";
      return true;
    },

    validateBusinessType() {
      if (this.typeofbusiness.trim() === "") {
        this.errorMessage.typeofbusiness = " Please select business type";
        return false;
      }
      this.errorMessage.typeofbusiness = "";
      return true;
    },

    validateBedrooms() {
      if (this.noOfBedroom.trim() === "" ) {
        this.errorMessage.noOfBedroom = "Please input Number of Bedrooms";
        return false;
      }

      if (isNaN(this.noOfBedroom)) {
        this.errorMessage.noOfBedroom = "Number of bedrooms must be a number";
        return false;
      }

      this.errorMessage.noOfBedroom = "";
      return true;
    },

    validateBathrooms() {
      if (this.noOfBathroom.trim() === "" ) {
        this.errorMessage.noOfBathroom = "Please input Number of Bathrooms";
        return false;
      }

      if (isNaN(this.noOfBathroom)) {
        this.errorMessage.noOfBathroom = "Number of bathrooms must be a number";
        return false;
      }

      this.errorMessage.noOfBathroom = "";
      return true;
    },

    validateToilets() {
      if (this.noOfToilets.trim() === "" ) {
        this.errorMessage.noOfToilets = "Please input Number of Toilets";
        return false;
      }

      if (isNaN(this.noOfToilets)) {
        this.errorMessage.noOfToilets = "Number of toilets must be a number";
        return false;
      }

      this.errorMessage.noOfToilets = "";
      return true;
    },

    // validateSquareFootage() {
    //   if (this.squareFootage === "") {
    //     this.errorMessage.squareFootage = "Please enter value";
    //     return false;
    //   }

    //   this.errorMessage.squareFootage = "";
    //   return true;
    // },

    validatePropertyType() {
      if (this.propertyType === "") {
        this.errorMessage.propertyType = "Please select a property type";
        return false;
      }

      this.errorMessage.propertyType = "";
      return true;
    },
    validatePropertySubType() {
      if (this.propertySubType === "") {
        this.errorMessage.propertySubType = "Please select value";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },
    // validateRented() {
    //   // this.errorMessage.leaseOrRent = "Please select value";

    //   if (this.leaseOrRent == "") {
    //     this.errorMessage.leaseOrRent = "Please select value";
    //     return false;
    //   }

    //   this.errorMessage.leaseOrRent = "";
    //   return true;
    // },

    validateStatus() {
      if (this.propertyStatus === "") {
        this.errorMessage.status = "Please select property status";
        return false;
      }

      this.errorMessage.status = "";
      return true;
    },
    validateLocation() {
      if (this.location === "") {
        this.errorMessage.location = "Please select state";
        return false;
      }

      this.errorMessage.location = "";
      return true;
    },

    validateCity() {
      if (this.city === "") {
        this.errorMessage.city = "Please select city";
        return false;
      }

      this.errorMessage.city = "";
      return true;
    },

    validatePropertyValue() {
      if (this.amount2 === "") {
        this.errorMessage.amount2 = "Please enter property value";
        return false;
      }

      if (isNaN(this.amount2)) {
        this.errorMessage.amount2 = "Property value must be a Number";
        return false;
      }

      this.errorMessage.amount2 = "";
      return true;
    },
    validateFirstName() {
      if (this.firstName.trim() === "") {
        this.errorMessage.firstName = " First name required!";
        return false;
      }
      this.errorMessage.firstName = "";
      return true;
    },
    validateLastName() {
      if (this.lastName.trim() === "") {
        this.errorMessage.lastName = " Last name required!";
        return false;
      }
      this.errorMessage.lastName = "";
      return true;
    },
    validateDescription() {
      if (this.description.trim() === "") {
        this.errorMessage.description = " Property description required!";
        return false;
      }
      this.errorMessage.description = "";
      return true;
    },
    validateEmail() {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      if (this.email.trim() === "") {
        this.errorMessage.email = " Email required!";
        return false;
      }

      if (!regex.test(this.email)) {
        this.errorMessage.email = "Invalid Email";
        return false;
      }

      this.errorMessage.email = "";
      return true;
    },
    validateAddress() {
      if (this.address.trim() === "") {
        this.errorMessage.address = " address required!";
        return false;
      }
      this.errorMessage.address = "";
      return true;
    },
    validatePhone() {
      if (this.phone.trim() === "") {
        this.errorMessage.phone = " Number required!";
        return false;
      }
      if (
        isNaN(this.phone.trim()) ||
        this.phone.trim().length < 11 ||
        Number(this.phone.trim()) < 0
      ) {
        this.errorMessage.phone = " Invalid number";
        return false;
      }

      this.errorMessage.phone = "";
      return true;
    },
    validateGender() {
      if (this.gender === "") {
        this.errorMessage.gender = " gender required!";
        return false;
      }
      this.errorMessage.gender = "";
      return true;
    },
    validatePropertyImage() {
      if (this.uploadedPhotos.length == 0) {
        this.errorMessage.propertyImageFile = "Property image(s) required!";
        return false;
      }
      this.errorMessage.propertyImageFile = "";
      return true;
    },

    validatePropertyDoc() {
      if (this.evidenceFile === '') {
        this.errorMessage.evidenceFile = "Property document required!";
        return false;
      }
      this.errorMessage.evidenceFile = "";
      return true;
    },

    validateForm1() {
      if (this.isForm1Valid) {
        this.formStep = 2;
        return;
      }
    },
    validateForm2() {
      if (this.isForm2Valid) {
        this.formStep = 3;
        return;
      }
    },
    validateForm3() {
      if (this.isForm3Valid) {
        this.formStep = 4;
        return;
      }
    },
    validateForm4() {
      if (this.isForm4Valid) {
        this.formStep = 4;
        return;
      }
      return this.isForm4Valid;
    },
    checkViewport() {
      this.isMobile = window.innerWidth < 768; // Change 768 to your breakpoint
    }
  },
  created() {
    this.subscription = this.$store.getters.getSubscription;
    this.user = this.$store.getters.getUser;
  },
  mounted() {
    this.getPropertyTypes();
    this.getStates();
    this.getPropertyAwarenessTypes();
    this.getPropertyStatuses();
    this.getBusinessTypes();
    this.checkViewport();
    this.facilitiesAndAmenities();
    window.addEventListener('resize', this.checkViewport);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkViewport);
  },
  watch: {
    propertyType(newType) {
       if (newType) {
        this.subtypeId = this.propertyTypes.find((el) => el.type === newType)[
          "id"
        ];
       }

        if (this.subtypeId) {
          this.getPropertySubTypes(this.subtypeId);
        } else {
          this.propertySubTypes = [];
        }
      // if (newType) {
      //    this.subtypeId = this.propertyTypes.find((el) => el.type === newType)[
      //     "id"
      //   ];

      //   if (this.subtypeId) {
      //     this.getPropertySubTypes(this.subtypeId);
      //   } else {
      //     this.propertySubTypes = [];
      //   }
      // }
    },
    location(newState) {
       if (newState) {
        this.stateIdd = this.states.find((el) => el.name === newState)["id"];
       }

        if (this.stateIdd) {
          this.getCities(this.stateIdd);
        } else {
          this.cities = [];
        }
    },
    

  },
  computed: {
    sortedStates() {
      return [...this.states].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    sortedCity() {
      return [...this.cities].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    propertyTypeId() {
      return (
        this.propertyTypes.find((el) => el.type === this.propertyType).id || ""
      );
    },
    propertySubTypeId() {
      return (
        this.propertySubTypes.find((el) => el.subtype === this.propertySubType)
          .id || ""
      );
    },
    stateId() {
      return this.states.find((el) => el.name === this.location).id || "";
    },
    cityId() {
      return this.cities.find((el) => el.name === this.city).id || "";
    },

    isForm1Valid() {
      return (
        // this.validateEmail() &&
        // this.validateFirstName() &&
        // this.validateLastName() &&
        // this.validatePhone() &&
        // this.validateGender() &&
        // this.validateAddress()
        this.validateTitleInput() &&
        this.validatePropertyValue() &&
        this.validatePropertyType() &&
        this.validatePropertySubType() &&
        // this.validateBusinessType() &&
        // this.validateRented() &&
        this.validateLocation() &&
        this.validateCity()
        // this.validateStatus()

        
      );
    },
    isForm2Valid() {
      return (
        this.validateBedrooms() &&
        this.validateBathrooms() && 
        this.validateToilets() && 
        // this.validateSquareFootage() && 
        this.validateDescription() 
      );
    },
    isForm3Valid() {
      return (
        this.validatePropertyDoc() &&
        this.validatePropertyImage()
      )
    },
    isForm4Valid() {
      return this.validateBedrooms();
    },
    isFormValid() {
      return (
        this.validateTitleInput() &&
        this.validateBedrooms() &&
        this.validateBathrooms() && 
        this.validatePropertyType() &&
        this.validatePropertySubType() &&
        this.validateBusinessType() &&
        // this.validateSquareFootage() &&
        // this.validateRented() &&
        this.validateStatus() &&
        this.validateState() &&
        this.validatePropertyValue() &&
        this.validatePropertyDoc() &&
        this.validatePropertyImage() &&
        this.landlordFirstname !== "" &&
        this.landlordLastname !== "" &&
        this.landlordGender !== "" &&
        !isNaN(this.landlordPhone)
      );
    },
    theRentValueAmount: {
      get() {
        return this.amount;
      },
      set(value) {
        let amountt = value.split(",").join("");
        this.amount2 = (amountt);
        let decimalArray = amountt.split(".");
        if (decimalArray.length === 2) {
          amountt = decimalArray[0];
          amountt = (+amountt).toLocaleString();
          this.amount = amountt + "." + decimalArray[1];
        } else {
          this.amount = (+amountt).toLocaleString();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">

// Progress indicator starts
/*progressbar*/

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  // color: white;
  color: #555C74;
  text-transform: uppercase;
  // font-size: 9px;
  // width: 33.33%;
  width: 24.33%;
  float: left;
  position: relative;

  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;

  // display: flex;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #333;
  background: white;
  border-radius: 50px;
  margin: 0 auto 5px auto;
  position: relative;
  z-index: 3;
}


/*progressbar connectors*/

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #A1AEBE;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: 2;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #0033ea;
  color: white;
}
// Progress indicator ends


.progress {
  height: fit-content !important;
  background: none !important;
}
.input-error {
  color: rgb(234, 0, 0);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 10px;
  padding: 1rem 2rem 0 2rem;

  /* position: absolute; */
  /* bottom: -20px; */
}

.submit-btn {
  padding: 1rem 2rem;
  background-color: var(--green-color);
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 50px;
  color: white;
  margin: auto;
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}
.container__head {
  padding-top: 1.5rem;
  margin-bottom: 1rem;
}
.form-radios {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.container__head {
  padding-top: 1.5rem;
  margin-bottom: 1rem;
}

.container__head h3 {
  font-size: clamp(18px, 4vw, 24px);
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.container__head p {
  font-size: 12px;
  font-weight: 700;
  color: #555c74;
  font-weight: 300;
}
.container {
  background-color: white;
  border-radius: 10px;

  padding: 2rem;
}

.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1.5rem;
  font-family: "Lato", sans-serif;
  padding: 20px;
}

.radios-label {
  line-height: 1.5;
  font-size: clamp(12px, 4vw, 1rem);
  font-weight: 700;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.input-group > * {
  flex: 1;
}

.radio-label {
  position: relative;
  left: -19px;
  color: #57595a;
  font-size: 14px;
  line-height: 1.8;
}

.form-check {
  .form-check-input {
    width: 20px;
    height: 20px;
  }
  label {
    margin-top: 3px;
    margin-left: 10px;
    color: #57595a;
    font-size: 14px;
    // line-height: 1.8;
  }
}

@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}

p {
  margin: 0;
}

.steps-container {
  font-family: "Lato";
  background-color: #fff;
  padding: 1.5rem 1rem;
  border-radius: 10px;
  max-width: 292px;
}
.steps-container h4 {
  margin-bottom: 1.5rem;
}

.steps > * + * {
  margin-top: 8px;
}

.step {
  display: flex;
  gap: 1rem;
  position: relative;
}
.step-number-container {
  position: relative;
}
.step:not(:last-child) .step-number-container::after {
  content: "";
  position: absolute;
  height: calc(100% - 28px);
  width: 2px;
  border-radius: 5px;
  background-color: #b1b2b2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 1;
  transition: background-color 500ms ease-in-out;
}

.step .step-count {
  color: #888fa7;

  font-size: 10px;
  line-height: 1.8;
}

.step .current-step-name {
  font-size: 12px;
  line-height: 1.8;
  color: #b1b2b2;
}

.step .status-pill {
  display: flex;
  font-size: 8px;
  letter-spacing: 0.16px;
  width: 62px;
  padding: 2px 8px;
  line-height: 1.5;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 5px 0;
  border: 1px solid #b1b2b2;
}

.progress .status-pill {
  background: #d8effd;
  color: #5aa8d7;
  border-color: transparent;
}
.completed .status-pill {
  background: #e7faf1;
  color: #48b881;
  border-color: transparent;
}

.submit-photo-btn {
  margin-top: 25px;
  button {
    border: #0033ea;
    color: #0033ea;
    font-family: Lato;
    font-size: 14px;
  }
}

.error-invisible {
  color: rgb(234, 0, 0);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.uploading-doc {
  color: #48b881 !important;
  text-align: center;
  margin-top: 20%;
  font-size: 14px !important;
  animation: blink 1s infinite; 
}

.step .step-number {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: grid;
  place-items: center;
  font-weight: 500;
  border: 2px solid #b1b2b2;
  color: #b1b2b2;

  font-size: 12px;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.step-number {
  position: relative;
  overflow: hidden;
  transition: background-color 500ms ease-in-out;
}

.step-number .done-icon {
  position: absolute;
  background-color: #0033ea;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 350ms ease-in;
  opacity: 0;
}

.pending.step .step-name {
  color: #b1b2b2;
}

.progress.step {
  color: #b1b2b2;
}

.progress.step .current-step-name {
  color: #262626;
}
.progress.step:not(:last-child) .step-number-container::after,
.completed.step:not(:last-child) .step-number-container::after {
  background-color: #0033ea;
}

.progress.step .step-number,
.completed.step .step-number {
  border-color: #0033ea;
  background-color: #0033ea;

  color: #fff;
}

.completed.step .done-icon {
  opacity: 1;
}
.completed.step .current-step-name {
  color: #0033ea;
}

.register-property-layout {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  margin-top: 2rem;
}

.steps-container {
  align-self: flex-start;
  flex-basis: 250px;
  display: none;
}

.features-amenities-holder {
  p {
    padding-left: 20px;
  }
}
.features-btn {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  text-align: center;
  color: #555C74;
  border: 1px solid #A1A2A3;
  border-radius: 50px;
  margin: 10px 10px 10px 0;
  box-shadow: none;
  outline: 0;
}

.selectedFeature {
  background-color: #0033ea;
  color: #ffffff;
  border: 1px solid #0033ea;
}

.upload-area {
  border: 1px dashed #CCD6FB;
  text-align: center;
  padding: 40px 30px;
  // position: relative;
  // animation: dash 5s linear infinite;
  // background-color: transparent;
  p {
    padding-left: 0 !important;
    color: #0033ea;
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
  }
  small {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #2B3352;
  }
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.submitting-text {
  // color: #48b881 !important;
  // text-align: center;
  // margin-top: 20%;
  // font-size: 14px !important;
  animation: blink 1s infinite; 
}

.file-display {
  position: relative;
  width: max-content;
  padding: 10px 15px;
  border: 1px solid #CCD6FB;
  margin-top: 15px;
  margin-right: 15px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  span {
    font-size: 10px;
    // bottom: 31px;
    // position: absolute;
    // right: -10%;
    margin-left: 15px;
    border: 1px solid #CCD6FB;
    border-radius: 50px;
    padding: 5px 8px;
    color: #2B3352;
    cursor: pointer;
    outline: 0;
    box-shadow: none;
  }
  h6 {
    color: #2B3352 !important;
    margin-bottom: 0 !important;
    font-size: 12px;
    font-family: Lato;
  }
}

// .upload-area::before {
//   content: '';
//   position: absolute;
//   top: -3px;
//   left: -3px;
//   right: -3px;
//   bottom: -3px;
//   border: 3px dashed transparent;
//   border-radius: inherit;
//   background: linear-gradient(to right, black 50%, transparent 0%) 0 0 / 50% 50% repeat;
//   animation: dash 2s linear infinite;
// }

// @keyframes dash {
//   100% {
//     background-position: 100% 0;
//   }
// }

.media-holder {
  cursor: pointer;
  h4 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
    margin-bottom: 1px;
  }
  p {
    padding-left: 18px;
    margin-bottom: 10px !important;
  }
}


.finish-header {
  h5, p {
    font-family: Lato;
  }
  h5 {
    color: #555C74;
  }
  p {
    color: #555C74
  }
}

.finish-holder {
  margin: 0 12rem;
}

// PROPERTY CARD PREVIEW STARTS
.property-preview-holder {
  box-shadow: 0px 4px 15px 0px #00000026;
  border-radius: 10px;
  padding: 16px;
  cursor: pointer;
}
.preview-img {
  border-radius: 10px;
  // max-width: 325.33px;
  min-height: 93px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prop-preview-details {
  h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #555C74;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h6 {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
  }
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: left;
    color: #2B3352;
  }
  small {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #555C74;
  }
  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #3E4958;
    span {
      color: #EA0033;
    }
  }
}
// PROPERTY CARD PREVIEW ENDS

@media screen and (min-width: 1024px) {
  .steps-container {
    display: block;
  }
}

.forms-container {
  flex: 1;
}



@media screen and (max-width: 599px) { 
  .container {
    padding: 0;
  }
  .finish-holder {
    margin: 0;
  }
  #progressbar {
    padding-top: 30px;
  }
  #progressbar li {
    font-size: 9px;
   }
}
 
</style>
